<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <h2>{{ $t('storeItemsList') }}</h2>
      <grid-view-table
        :id="'store-item'"
        :columns="columns"
        :path="'store-item'"
        :add-route-params="addLinkParams"
        :extra-params="extraParams"
      />
    </div>
  </div>
</template>

<script>
  import GridViewTable from '../../components/GridViewTable';
  import { columns } from './grid-views/store-items';

  export default {
    name: 'StoreItemsList',
    components: {
      GridViewTable,
    },
    props: {
      storeId: {
        type: String,
        required: true,
      },
    },
    data: () => {
      return {
        columns,
      }
    },
    computed: {
      addLinkParams: function () {
        return {storeId: this.storeId}
      },
      extraParams: function () {
        return '&join=prices&join=store&join=barcode&join=product&join=tagItemPrice&join=tagItemPrice.tagItem&filter=store.id||eq||' + this.storeId
      }
    },
  }
</script>
