export const columns = [
  {
    label: 'product',
    field: 'product.brandName',
    width: '40%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'tagItemPrice',
    field: 'tagItemPrice.tagItem.value',
    width: '30%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'storeQuantityPrices',
    field: 'prices',
    html: true,
    width: '20%',
    formatRowValue: true,
    formatFn: (value) => {
      let str = '';
      if (value.prices) {
        value.prices.forEach(priceQuantityRecord => {
          str += `<i>${priceQuantityRecord.price} грн.</i> - ${priceQuantityRecord.quantity} шт <br>`;
        });
      }
      return str;
    },
    filterOptions: {
      enabled: false,
    },
  },
  {
    label: 'quantityShort',
    field: 'prices',
    width: '10%',
    formatRowValue: true,
    formatFn: (value) => {
      return value.prices ? value.prices.reduce((acc, priceQuantityRecord) => acc + priceQuantityRecord.quantity, 0) : 0;
    },
    filterOptions: {
      enabled: false,
    },
  },
]
